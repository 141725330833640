import React from "react";
import PropTypes from "prop-types";
import chipAmountFormatter from "@livecasino/core/helpers/chipAmountFormatter";
import BlackJackBG from "../../../assets/images/BlackJack.png";
import ViewersPlayers from "@livecasino/core/v2/components/ViewersPlayersBetters";

import styles from "./index.module.scss";

const BlackJack = ({ currencyCode, totalBetAmount, players, viewers, betters, availableSeats, lastAction = false }) => {
    return (
        <div className={styles.BlackJack}>
            <img src={BlackJackBG} alt="img" className={styles.Image} />
            <div className={styles.Text}>
                {!lastAction && (
                    <>
                        <span className={styles.TotalBet}>{`${chipAmountFormatter(
                            totalBetAmount
                        )} ${currencyCode}`}</span>
                        <div className={styles.PlayersAndSeats}>
                            <ViewersPlayers
                                availableSeats={availableSeats}
                                players={players}
                                viewers={viewers}
                                betters={betters}
                            />
                        </div>
                    </>
                )}
            </div>
        </div>
    );
};

BlackJack.propTypes = {
    currencyCode: PropTypes.string.isRequired,
    totalBetAmount: PropTypes.number.isRequired,
    players: PropTypes.number.isRequired,
    viewers: PropTypes.number.isRequired,
    betters: PropTypes.number.isRequired,
    availableSeats: PropTypes.number.isRequired,
    lastAction: PropTypes.bool
};

export default BlackJack;
