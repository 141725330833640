import React, { useState, useEffect } from "react";
import Table from "../Table";
import PropTypes from "prop-types";
import SideBar from "../SideBar";
import { GameTypes, CLASSIC_BJ_AVAILABLE_SEATS } from "../../shared/constants";

import styles from "./index.module.scss";

const VideoWall = ({ tables = [], issues = [], issuesCounts = {} }) => {
    const [width, setWidth] = useState(null);
    const [height, setHeight] = useState(null);
    const [cols, setCols] = useState(null);
    const countOfTables = tables.length;
    const screenWidth = window.innerWidth;
    const screenHeight = window.innerHeight;
    const containerHeight = screenHeight - 20;
    const containerWidth = screenWidth - 20 - 350;
    const proportion = 1.5;
    useEffect(() => {
        findBestFit(containerWidth, containerHeight, countOfTables, proportion);
    }, [containerWidth, containerHeight, countOfTables, proportion]);

    function findBestFit(containerWidth, containerHeight, countOfTable, proportion) {
        let bestFitProperties = {
            area: 0,
            cols: 0,
            rows: 0,
            width: 0,
            height: 0
        };

        // For each combination of rows and cols, calculate the biggest area of table.
        for (let cols = countOfTable; cols > 0; cols--) {
            const rows = Math.ceil(countOfTable / cols);
            const hScale = containerWidth / (cols * proportion);
            const vScale = containerHeight / rows;
            let width;
            let height;
            // Determine which axis is the constraint.
            if (hScale <= vScale) {
                width = containerWidth / cols;
                height = width / proportion;
            } else {
                height = containerHeight / rows;
                width = height * proportion;
            }
            const area = width * height;
            if (area > bestFitProperties.area) {
                bestFitProperties = { area, width, height, rows, cols };
            }
        }
        setCols(bestFitProperties.cols);
        setWidth(bestFitProperties.width);
        setHeight(bestFitProperties.height);
    }
    const fontSize = height / 10;
    let containerWrapper = cols * width;
    let widthOfTableWithoutMargin = width - 10;
    let heightOfTableWithoutMargin = height - 10;

    const renderGame = table => {
        const { gameKind, currencyCode, totalBetAmount, players, lastAction } = table;
        if (gameKind === GameTypes.Blackjack) {
            return (
                <Table.BlackJack
                    currencyCode={currencyCode}
                    totalBetAmount={totalBetAmount}
                    players={players}
                    viewers={table.viewers}
                    betters={table.betters}
                    availableSeats={CLASSIC_BJ_AVAILABLE_SEATS}
                    lastAction={lastAction && lastAction.type !== null}
                />
            );
        }
        if (gameKind === GameTypes.Roulette) {
            return (
                <Table.Roulette
                    currencyCode={currencyCode}
                    totalBetAmount={totalBetAmount}
                    players={players}
                    viewers={table.viewers}
                    betters={table.betters}
                    lastAction={lastAction && lastAction.type !== null}
                />
            );
        }
        if (gameKind === GameTypes.Baccarat) {
            return (
                <Table.Baccarat
                    currencyCode={currencyCode}
                    totalBetAmount={totalBetAmount}
                    players={players}
                    viewers={table.viewers}
                    betters={table.betters}
                    lastAction={lastAction && lastAction.type !== null}
                />
            );
        }
        if (gameKind === GameTypes.DragonTiger) {
            return (
                <Table.DragonTiger
                    currencyCode={currencyCode}
                    totalBetAmount={totalBetAmount}
                    players={players}
                    viewers={table.viewers}
                    betters={table.betters}
                    lastAction={lastAction && lastAction.type !== null}
                />
            );
        }
        if (gameKind === GameTypes.PiratePlunder) {
            return (
                <Table.PiratePlunder
                    currencyCode={currencyCode}
                    totalBetAmount={totalBetAmount}
                    players={players}
                    viewers={table.viewers}
                    betters={table.betters}
                    lastAction={lastAction && lastAction.type !== null}
                />
            );
        }
        if (gameKind === GameTypes.SicBo) {
            return (
                <Table.SicBo
                    currencyCode={currencyCode}
                    totalBetAmount={totalBetAmount}
                    viewers={table.viewers}
                    betters={table.betters}
                    lastAction={lastAction && lastAction.type !== null}
                />
            );
        }
    };

    return (
        <>
            <div className={styles.Main}>
                <div
                    className={`${styles.Container} custom-scroll`}
                    style={{
                        width: `${containerWrapper}px`
                    }}
                >
                    {tables.map(table => (
                        <Table
                            fontSize={fontSize}
                            width={widthOfTableWithoutMargin}
                            height={heightOfTableWithoutMargin}
                            key={table.tableId}
                            gameId={table.gameId}
                            tableName={table.tableName}
                            dealerNickname={table.dealerNickname}
                            pitbosses={table.pitbosses}
                            lastAction={table.lastAction}
                            renderGame={() => renderGame(table)}
                        />
                    ))}
                </div>
            </div>
            <div className={styles.SideBar}>
                <SideBar
                    mistakeData={issues}
                    totalCountIssuesCount={issuesCounts.totalCount}
                    activeIssuesCount={issuesCounts.activeIssuesCount}
                />
            </div>
        </>
    );
};

VideoWall.propTypes = {
    tables: PropTypes.array,
    issues: PropTypes.array,
    issuesCounts: PropTypes.object
};

export default VideoWall;
