import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import styles from "./index.module.scss";

function Header({ activeIssuesCount = null, totalCountIssuesCount = null }) {
    const activeIssuesClasses = classNames(styles.NoActiveIssues, {
        [styles.IsActive]: activeIssuesCount > 0
    });

    return (
        <div className={styles.Header}>
            <span className={styles.Title}>Total:</span>
            <span className={activeIssuesClasses}>{activeIssuesCount}</span>
            {activeIssuesCount > 0 && <span className={styles.AllElement}>/{totalCountIssuesCount} </span>}
        </div>
    );
}

Header.propTypes = {
    totalCountIssuesCount: PropTypes.number,
    activeIssuesCount: PropTypes.number
};

export default Header;
