import React, { useMemo } from "react";
import queryString from "query-string";
import ErrorBoundary from "@livecasino/core/v2/components/ErrorBoundary";
import VideoWall from "./components/VideoWall";
import Modal from "@livecasino/core/v2/components/Modal";
import useVideoWallData from "hooks/useVideoWallData";
import useAuth from "@livecasino/core/hooks/useAuth";
import GlobalStyles2 from "@livecasino/core/v2/GlobalStyle";
import { AUTH_URL, ERROR_TRANSLATIONS } from "./shared/constants";
import ErrorContent from "@livecasino/core/v2/Backoffice_DMS_Pitboss/components/ErrorContent/ErrorContent";
import getErrorObjectByType from "@livecasino/core/helpers/getErrorObjectByType";
import { setDebugMode } from "@livecasino/core/helpers/logger";

setDebugMode(true);
const { token, refreshToken } = queryString.parse(location.search, {
    decode: false
});

function App() {
    const { getAsyncToken } = useAuth({
        token,
        refreshToken,
        frameUrl: AUTH_URL,
        keyPrefix: "PITBOSS"
    });

    const { games, issues, issuesCounts, error } = useVideoWallData({
        getAsyncToken
    });

    const errorObject = useMemo(() => {
        if (!error) return null;
        return getErrorObjectByType({ error, translation: ERROR_TRANSLATIONS });
    }, [error]);

    return (
        <>
            <GlobalStyles2 />
            <ErrorBoundary>
                <div className="App">
                    <VideoWall tables={games} issues={issues} issuesCounts={issuesCounts} />
                    <Modal open={Boolean(error)}>
                        <ErrorContent title={errorObject?.title} textsArray={[errorObject?.text]} />
                    </Modal>
                </div>
            </ErrorBoundary>
        </>
    );
}

export default App;
